import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ErrorBoundary from './components/ErrorBoundary';
import ProtectedRoute from './components/ProtectedRoute';
import AdminGuard from './components/AdminGuard';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SupportWidget from './components/support/SupportWidget';
import { initializeAnalytics, logPageView } from './lib/analytics';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const MainPage = lazy(() => import('./pages/MainPage'));
const Learning = lazy(() => import('./pages/Learning'));
const Roadmap = lazy(() => import('./pages/Roadmap'));
const Course = lazy(() => import('./pages/Course'));
const Ebook = lazy(() => import('./pages/Ebook'));
const Success = lazy(() => import('./pages/Success'));
const Admin = lazy(() => import('./pages/Admin'));
const AdminSetup = lazy(() => import('./pages/AdminSetup'));
const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));

function LoadingSpinner() {
  return (
    <div className="min-h-screen bg-nude-light flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-accent"></div>
    </div>
  );
}

function AppContent() {
  const location = useLocation();

  React.useEffect(() => {
    initializeAnalytics();
    logPageView(location.pathname);
  }, [location]);

  return (
    <div className="min-h-screen bg-nude-light flex flex-col">
      <Helmet>
        <title>90 Days to Self Mastery - Transform Your Life</title>
        <meta name="description" content="Transform your life through daily practice and conscious growth. Master yourself in just 90 days with our proven system." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      
      <Navbar />
      <main className="flex-grow">
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/proven-system" element={<MainPage />} />
            <Route path="/learning" element={<Learning />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/course/*" element={<Course />} />
            <Route path="/ebook" element={<Ebook />} />
            <Route path="/success" element={<Success />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/admin-setup" element={<AdminSetup />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <AdminGuard>
                  <Admin />
                </AdminGuard>
              }
            />
          </Routes>
        </Suspense>
      </main>
      <Footer />
      <SupportWidget />
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Router>
          <AppContent />
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export default App;