import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBq_2PfCmwY-AXIJ6gvMkxdDjBq9yiR0lw",
  authDomain: "mindnmotion-b4e63.firebaseapp.com",
  projectId: "mindnmotion-b4e63",
  storageBucket: "mindnmotion-b4e63.firebasestorage.app",
  messagingSenderId: "969239672641",
  appId: "1:969239672641:web:0f7487f0a71bd9d19313c0",
  measurementId: "G-WN55MYCQY2"
};

// Initialize Firebase services
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

// Analytics helper function
export const logAnalyticsEvent = (eventName: string, eventParams?: Record<string, any>) => {
  try {
    logEvent(analytics, eventName, eventParams);
  } catch (error) {
    console.error('Analytics event logging failed:', error);
  }
};

export default app;