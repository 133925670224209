import { collection, addDoc, serverTimestamp, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { db, auth } from './firebase';
import { Message } from '../types/chat';
import { trackError } from './analytics';

export const startChatSession = async () => {
  try {
    const user = auth.currentUser;
    const chatRef = await addDoc(collection(db, 'chat_logs'), {
      userId: user?.uid || 'anonymous',
      userEmail: user?.email || 'anonymous',
      messages: [],
      startedAt: serverTimestamp(),
      endedAt: null
    });
    return chatRef.id;
  } catch (error) {
    trackError('Failed to start chat session', 'chat');
    throw error;
  }
};

export const addMessageToChat = async (sessionId: string, message: Message) => {
  try {
    const chatRef = doc(db, 'chat_logs', sessionId);
    await updateDoc(chatRef, {
      messages: arrayUnion(message)
    });
  } catch (error) {
    trackError('Failed to add message to chat', 'chat');
    throw error;
  }
};

export const endChatSession = async (sessionId: string) => {
  try {
    const chatRef = doc(db, 'chat_logs', sessionId);
    await updateDoc(chatRef, {
      endedAt: serverTimestamp()
    });
  } catch (error) {
    trackError('Failed to end chat session', 'chat');
    throw error;
  }
};