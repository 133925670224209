import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Book, GraduationCap, LayoutDashboard, Menu, X, BookOpen, Info } from 'lucide-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../lib/firebase';
import Logo from './Logo';

function Navbar() {
  const [user] = useAuthState(auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className="bg-white shadow-soft sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link 
              to="/" 
              className="flex items-center gap-2"
              aria-label="90 Days to Self Mastery Home"
            >
              <Logo className="h-12 w-12" />
              <span className="text-lg font-semibold text-brand-bg hidden sm:block">
                90 Days to Self Mastery
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-8">
            <Link
              to="/about"
              className="inline-flex items-center px-1 pt-1 text-sm font-medium text-brand-bg hover:text-brand-orbit-pink transition-colors"
            >
              <Info className="h-5 w-5 mr-1" aria-hidden="true" />
              About
            </Link>
            <Link
              to="/learning"
              className="inline-flex items-center px-1 pt-1 text-sm font-medium text-brand-bg hover:text-brand-orbit-pink transition-colors"
            >
              <BookOpen className="h-5 w-5 mr-1" aria-hidden="true" />
              Learning
            </Link>
            <Link
              to="/course"
              className="inline-flex items-center px-1 pt-1 text-sm font-medium text-brand-bg hover:text-brand-orbit-pink transition-colors"
            >
              <GraduationCap className="h-5 w-5 mr-1" aria-hidden="true" />
              Course
            </Link>
            <Link
              to="/ebook"
              className="inline-flex items-center px-1 pt-1 text-sm font-medium text-brand-bg hover:text-brand-orbit-pink transition-colors"
            >
              <Book className="h-5 w-5 mr-1" aria-hidden="true" />
              eBook
            </Link>
            {user && (
              <Link
                to="/dashboard"
                className="inline-flex items-center px-1 pt-1 text-sm font-medium text-brand-bg hover:text-brand-orbit-pink transition-colors"
              >
                <LayoutDashboard className="h-5 w-5 mr-1" aria-hidden="true" />
                Dashboard
              </Link>
            )}
            {user ? (
              <button
                onClick={() => auth.signOut()}
                className="btn-primary"
              >
                Sign Out
              </button>
            ) : (
              <div className="flex space-x-4">
                <Link to="/login" className="btn-secondary">
                  Sign In
                </Link>
                <Link to="/signup" className="btn-primary">
                  Sign Up
                </Link>
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-brand-bg hover:text-brand-orbit-pink focus:outline-none"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="pt-2 pb-3 space-y-1">
          <Link
            to="/about"
            className="block px-4 py-2 text-base font-medium text-brand-bg hover:bg-nude-light"
            onClick={toggleMenu}
          >
            <div className="flex items-center">
              <Info className="h-5 w-5 mr-2" aria-hidden="true" />
              About
            </div>
          </Link>
          <Link
            to="/learning"
            className="block px-4 py-2 text-base font-medium text-brand-bg hover:bg-nude-light"
            onClick={toggleMenu}
          >
            <div className="flex items-center">
              <BookOpen className="h-5 w-5 mr-2" aria-hidden="true" />
              Learning
            </div>
          </Link>
          <Link
            to="/course"
            className="block px-4 py-2 text-base font-medium text-brand-bg hover:bg-nude-light"
            onClick={toggleMenu}
          >
            <div className="flex items-center">
              <GraduationCap className="h-5 w-5 mr-2" aria-hidden="true" />
              Course
            </div>
          </Link>
          <Link
            to="/ebook"
            className="block px-4 py-2 text-base font-medium text-brand-bg hover:bg-nude-light"
            onClick={toggleMenu}
          >
            <div className="flex items-center">
              <Book className="h-5 w-5 mr-2" aria-hidden="true" />
              eBook
            </div>
          </Link>
          {user && (
            <Link
              to="/dashboard"
              className="block px-4 py-2 text-base font-medium text-brand-bg hover:bg-nude-light"
              onClick={toggleMenu}
            >
              <div className="flex items-center">
                <LayoutDashboard className="h-5 w-5 mr-2" aria-hidden="true" />
                Dashboard
              </div>
            </Link>
          )}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          {user ? (
            <div className="px-4">
              <button
                onClick={() => {
                  auth.signOut();
                  toggleMenu();
                }}
                className="w-full btn-primary text-center"
              >
                Sign Out
              </button>
            </div>
          ) : (
            <div className="px-4 space-y-2">
              <Link
                to="/login"
                className="block w-full btn-secondary text-center"
                onClick={toggleMenu}
              >
                Sign In
              </Link>
              <Link
                to="/signup"
                className="block w-full btn-primary text-center"
                onClick={toggleMenu}
              >
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;