import { v4 as uuidv4 } from 'uuid';
import { Message } from '../types/chat';

export const generateMessageId = (): string => {
  return uuidv4();
};

export const createMessage = (text: string, from: 'user' | 'bot'): Message => {
  return {
    id: generateMessageId(),
    text,
    from,
    timestamp: Date.now(),
  };
};