import React from 'react';
import { MessageCircle } from 'lucide-react';

interface SupportButtonProps {
  onClick: () => void;
}

const SupportButton = ({ onClick }: SupportButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="fixed bottom-5 right-5 bg-brand-orbit-pink text-white rounded-full w-14 h-14 flex items-center justify-center shadow-lg hover:bg-brand-orbit-pink/90 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-orbit-pink"
      aria-label="Open support chat"
    >
      <MessageCircle className="h-6 w-6" />
    </button>
  );
};

export default SupportButton;