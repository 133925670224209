import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../lib/firebase';
import { isAdmin } from '../lib/admin';
import { Loader2 } from 'lucide-react';

interface AdminGuardProps {
  children: React.ReactNode;
}

function AdminGuard({ children }: AdminGuardProps) {
  const [user, loading] = useAuthState(auth);
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [checking, setChecking] = React.useState(true);

  React.useEffect(() => {
    async function checkAdminStatus() {
      if (!user) {
        setChecking(false);
        return;
      }

      try {
        const adminStatus = await isAdmin(user.uid);
        setIsAuthorized(adminStatus);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAuthorized(false);
      } finally {
        setChecking(false);
      }
    }

    if (!loading) {
      checkAdminStatus();
    }
  }, [user, loading]);

  if (loading || checking) {
    return (
      <div className="min-h-screen bg-nude-light flex items-center justify-center">
        <Loader2 className="h-12 w-12 animate-spin text-brand-orbit-pink" />
      </div>
    );
  }

  if (!user || !isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}

export default AdminGuard;