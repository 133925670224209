import React from 'react';
import { Link } from 'react-router-dom';
import { Heart } from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-white shadow-soft mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center space-x-2">
            <span className="text-gray-600">Made with</span>
            <Heart className="h-4 w-4 text-red-500" aria-hidden="true" />
            <span className="text-gray-600">for your transformation</span>
          </div>
          <nav className="mt-4 md:mt-0">
            <ul className="flex flex-wrap gap-6">
              <li>
                <Link 
                  to="/course" 
                  className="text-gray-600 hover:text-accent"
                  aria-label="Browse our courses"
                >
                  Course
                </Link>
              </li>
              <li>
                <Link 
                  to="/ebook" 
                  className="text-gray-600 hover:text-accent"
                  aria-label="Get our eBook"
                >
                  eBook
                </Link>
              </li>
              <li>
                <Link 
                  to="/privacy-policy" 
                  className="text-gray-600 hover:text-accent"
                  aria-label="Read our privacy policy"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/terms-of-service" 
                  className="text-gray-600 hover:text-accent"
                  aria-label="Read our terms of service"
                >
                  Terms of Service
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="mt-4 text-center text-sm text-gray-500">
          © {new Date().getFullYear()} 90 Days to Mastery. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;