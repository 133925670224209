import React from 'react';
import { Brain, Orbit } from 'lucide-react';

interface LogoProps {
  className?: string;
}

function Logo({ className = "h-12" }: LogoProps) {
  return (
    <div className={`relative ${className} aspect-square flex items-center justify-center`}>
      {/* Central Brain Icon */}
      <Brain 
        className="text-brand-brain absolute h-3/4 w-3/4 z-10" 
        aria-hidden="true"
      />
      
      {/* Orbiting Elements */}
      <div className="absolute inset-0 animate-[spin_20s_linear_infinite]">
        <Orbit 
          className="text-brand-orbit-pink absolute top-0 left-1/2 -translate-x-1/2 h-1/3 w-1/3" 
          aria-hidden="true"
        />
      </div>
      
      <div className="absolute inset-0 animate-[spin_15s_linear_infinite_reverse]">
        <Orbit 
          className="text-brand-orbit-cyan absolute bottom-0 right-0 h-1/3 w-1/3" 
          aria-hidden="true"
        />
      </div>
      
      <div className="absolute inset-0 animate-[spin_25s_linear_infinite]">
        <Orbit 
          className="text-brand-orbit-orange absolute bottom-0 left-0 h-1/3 w-1/3" 
          aria-hidden="true"
        />
      </div>
    </div>
  );
}

export default Logo;