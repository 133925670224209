import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth } from './firebase';
import { trackError } from './analytics';

export async function isAdmin(userId: string): Promise<boolean> {
  try {
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() && userDoc.data()?.role === 'admin';
  } catch (error) {
    const message = error instanceof Error ? error.message : 'Failed to check admin status';
    trackError(message, 'isAdmin');
    return false;
  }
}

export async function createAdminUser(email: string, password: string, displayName: string) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await updateProfile(user, {
      displayName
    });

    const db = getFirestore();
    await setDoc(doc(db, 'users', user.uid), {
      email: user.email,
      displayName,
      role: 'admin',
      createdAt: new Date(),
      lastLogin: new Date()
    });

    return user;
  } catch (error) {
    const message = error instanceof Error ? error.message : 'Failed to create admin user';
    trackError(message, 'createAdminUser');
    throw error;
  }
}

export async function getUserStats() {
  try {
    const db = getFirestore();
    const usersRef = collection(db, 'users');
    
    // Total users
    const totalUsersSnapshot = await getDocs(usersRef);
    const totalUsers = totalUsersSnapshot.size;

    // Active users (logged in within last 7 days)
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    
    const activeUsersQuery = query(
      usersRef,
      where('lastLogin', '>=', sevenDaysAgo)
    );
    const activeUsersSnapshot = await getDocs(activeUsersQuery);
    const activeUsers = activeUsersSnapshot.size;

    // Premium users (with active subscriptions)
    const premiumUsersQuery = query(
      usersRef,
      where('hasPurchased', '==', true)
    );
    const premiumUsersSnapshot = await getDocs(premiumUsersQuery);
    const premiumUsers = premiumUsersSnapshot.size;

    return {
      totalUsers,
      activeUsers,
      premiumUsers
    };
  } catch (error) {
    const message = error instanceof Error ? error.message : 'Failed to fetch user stats';
    trackError(message, 'getUserStats');
    throw error;
  }
}