import React, { useState } from 'react';
import SupportButton from './SupportButton';
import ChatWindow from './ChatWindow';

const SupportWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => setIsOpen(!isOpen);

  return (
    <>
      <SupportButton onClick={toggleChat} />
      {isOpen && <ChatWindow onClose={() => setIsOpen(false)} />}
    </>
  );
};

export default SupportWidget;