import React, { useState, useRef, useEffect } from 'react';
import ChatHeader from './ChatHeader';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import { Message } from '../../types/chat';
import { createMessage } from '../../utils/chat';
import { startChatSession, addMessageToChat, endChatSession } from '../../lib/chat';
import { AlertCircle } from 'lucide-react';

interface ChatWindowProps {
  onClose: () => void;
}

const ChatWindow = ({ onClose }: ChatWindowProps) => {
  const [messages, setMessages] = useState<Message[]>([
    createMessage('Hi! How can I assist you today?', 'bot'),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initChat = async () => {
      try {
        const id = await startChatSession();
        setSessionId(id);
        // Add initial bot message to chat log
        if (messages.length > 0) {
          await addMessageToChat(id, messages[0]);
        }
      } catch (err) {
        setError('Failed to start chat session');
        console.error('Chat initialization error:', err);
      }
    };

    initChat();

    return () => {
      if (sessionId) {
        endChatSession(sessionId).catch(console.error);
      }
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (text: string) => {
    if (!sessionId) {
      setError('Chat session not initialized');
      return;
    }

    try {
      setError(null);
      setIsLoading(true);
      
      // Create and add user message
      const userMessage = createMessage(text, 'user');
      setMessages(prev => [...prev, userMessage]);
      await addMessageToChat(sessionId, userMessage);

      // Simulate bot response - replace with actual API integration
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Create and add bot response
      const botMessage = createMessage('Thank you for your message. Our team will get back to you soon.', 'bot');
      setMessages(prev => [...prev, botMessage]);
      await addMessageToChat(sessionId, botMessage);
    } catch (err) {
      setError('Failed to send message. Please try again.');
      console.error('Chat error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = async () => {
    if (sessionId) {
      try {
        await endChatSession(sessionId);
      } catch (err) {
        console.error('Failed to end chat session:', err);
      }
    }
    onClose();
  };

  return (
    <div className="fixed bottom-20 right-5 w-80 h-[500px] bg-white rounded-lg shadow-xl border border-gray-200 flex flex-col">
      <ChatHeader onClose={handleClose} />
      
      <div className="flex-1 overflow-y-auto p-4">
        {error && (
          <div className="flex items-center gap-2 text-red-500 bg-red-50 p-2 rounded mb-4">
            <AlertCircle className="h-4 w-4" />
            <p className="text-sm">{error}</p>
          </div>
        )}
        
        {messages.map(message => (
          <ChatMessage key={message.id} message={message} />
        ))}
        <div ref={messagesEndRef} />
      </div>

      <ChatInput onSend={handleSend} disabled={isLoading} />
    </div>
  );
};

export default ChatWindow;