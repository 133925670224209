import ReactGA from 'react-ga4';
import { logAnalyticsEvent } from './firebase';

export const initializeAnalytics = () => {
  const gaId = import.meta.env.VITE_GA_MEASUREMENT_ID;
  if (gaId) {
    ReactGA.initialize(gaId);
  }
};

export const logPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
  logAnalyticsEvent('page_view', { page_path: path });
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  // Log to Google Analytics
  ReactGA.event({
    category,
    action,
    label,
    value,
  });

  // Log to Firebase Analytics
  logAnalyticsEvent(action.toLowerCase(), {
    category,
    label,
    value,
  });
};

export const trackUserFlow = (step: string, label?: string) => {
  trackEvent('User Flow', step, label);
};

export const trackDownload = (fileName: string) => {
  trackEvent('Download', 'eBook', fileName);
};

export const trackCourseProgress = (moduleId: string, progress: number) => {
  trackEvent('Course', 'Progress', moduleId, progress);
};

export const trackLogin = (method: string) => {
  trackEvent('User', 'Login', method);
};

export const trackError = (error: string, context: string) => {
  trackEvent('Error', context, error);
};