import React from 'react';
import { X } from 'lucide-react';

interface ChatHeaderProps {
  onClose: () => void;
}

const ChatHeader = ({ onClose }: ChatHeaderProps) => {
  return (
    <header className="bg-brand-orbit-pink text-white p-4 flex items-center justify-between rounded-t-lg">
      <h2 className="text-lg font-semibold">Support Chat</h2>
      <button
        onClick={onClose}
        className="text-white hover:text-white/80 transition-colors"
        aria-label="Close support chat"
      >
        <X className="h-5 w-5" />
      </button>
    </header>
  );
};

export default ChatHeader;